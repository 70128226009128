<template>
    <div class="zacbox-logo">
        <img src="imgs/zacbox-logo.png" />&nbsp;Zacbox
    </div>
</template>

<style scoped>
  @import url(https://use.typekit.net/usy5hcb.css);

  .zacbox-logo {
    font-family: "Bilo", "Helvetica Neue", "Helvetica", sans-serif;
    font-size: 3rem;
    text-align: center;

    user-select: none;
    -webkit-user-drag: element;
  }
  .zacbox-logo img {
    height: 100px;
  }

  .zacbox-logo.medium img {
    height: 50px;
  }

  .zacbox-logo.small img {
    height: 30px;
  }
</style>
