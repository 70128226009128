<template>
    <div class="app-button-container">
        <a :href="`./${dst}`" class="button">
            <div class="app-button" :class="dst">
                <img :src="'imgs/' + img" />
            </div>
        </a>
    </div>
</template>

<script>
export default {
    props: {
        dst: {
            type: String,
            required: true
        },
        img: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
.app-button-container {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 160px;
    max-height: 160px !important;
}

.button {
    width: 356px;
}
.app-button {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    width: 356px;
    height: 150px;

    color: white;
    font-size: 2em;

    outline: 2px solid rgba(255, 255, 255, 0.35);
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    cursor: pointer;

    transition: all 0.2s, filter 0.1s;
}

.app-button img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}

.app-button:hover {
    height: 155px;
    scale: 1.025;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.app-button:active {
    height: 150px;
    filter: brightness(0.8);
    -webkit-filter: brightness(0.8);
}

.app-button.void {
  outline: 3px solid black;
}

.app-button.mcmap {
  outline: 3px solid #67ac67;
}

.app-button.clock {
  outline: none;
}
</style>
