<template>
  <main class="container full-width full-height">
    <div class="title flexbox flex-col dark-bg">
      <h1>
        <ZacboxLogo class="inline small" />
      </h1>
      <i>websites by zac</i>
    </div>
    <div class="content">
        <div class="apps">
          <AppButton dst="three-word-story" img="story-hero.png" />
          <AppButton dst="starmap" img="starmap-hero.png" />
          <AppButton dst="new-normal" img="newnormal-hero.png" />
          <AppButton dst="mcmap" img="mcmap-hero.png" />
          <AppButton dst="rc-clock" img="rcclock-hero.png" />
          <AppButton dst="void" img="void-hero.png" />
          <AppButton dst="deepdark" img="deepdark-hero.png" />
        </div>
    </div>
    <div class="bio flexbox flex-col dark-bg">
      <p>
        <b>Hi, I'm Zac</b>.
      </p>
      <p>I make websites and stuff.</p>
      <br/>
      <p>Send me a message:</p>
      <p>
        <ContactEmail />
      </p>
    </div>
  </main>
</template>

<script>
import ZacboxLogo from './components/ZacboxLogo.vue';
import AppButton from './components/AppButton.vue';
import ContactEmail from './components/ContactEmail.vue';

export default {
  name: 'App',
  components: {
    ZacboxLogo,
    AppButton,
    ContactEmail
  },
  head: {
    script: [
      {
        src: 'https://www.googletagmanager.com/gtag/js?id=G-3F26EZ70Q8',
        async: true
      },
      {
        src: '/gtag.js'
      }
    ],
    metaInfo: {
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
        { hid: 'description', name: 'description', content: 'Websites, games, and apps by Zac' },
        { name: 'format-detection', content: 'telephone=no' },
        { name: 'robots', content: 'index, follow' },
      ]
    }
  }
}
</script>

<style>
.title {
  text-align: center;
  padding: 20px 0;
}

.bio {
  margin-bottom: 50px;
  padding: 40px 0;
  border-radius: 4px;
  max-width: 1000px;
}

@media screen and (min-width: 730px) {
  .bio {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.footer {
  font-size: 0.66rem;
  height: 1.33rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.5)
}
</style>

