import { createApp } from 'vue';
import App from './App.vue';
import VueGtag from "vue-gtag";
import './assets/global.css'; // Importing global CSS

const GA_MEASUREMENT_ID = "G-3F26EZ70Q8";

const app = createApp({
  ...App,
  mounted() {
    document.title = 'Zacbox';
  },
});

app.use(VueGtag, {
    config: { id: GA_MEASUREMENT_ID }
})

app.mount('#app');
