<template comments>
    <a id="contact" :href="emailLink">hello<!-- asdk;jl]W2nnwelw -->&commat;<!-- ;uheq8iw.;beaspe -->zacbox<!-- 29nd-vuv187aBSX/ -->&#46;app</a>
</template>

<script>
export default {
    data() {
        return {
            email: "aGVsbG9AemFjYm94LmFwcA=="
        };
    },
    computed: {
        emailLink() {
            const decodedEmail = atob(this.email);
            return "mailto:" + decodedEmail;
        }
    }
};
</script>

<style scoped>
</style>
